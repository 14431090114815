import { css } from "styled-components";

export const wide = (...args) => css`
  @media screen and (min-width: 1300px) {
    ${css(...args)};
  }
`;

export const medium = (...args) =>
  css`
    @media screen and (max-width: 975px) {
      ${css(...args)};
    }

    @media screen and (max-height: 800px) {
      ${css(...args)};
    }
  `;

export const small = (...args) =>
  css`
    @media screen and (max-width: 745px) {
      ${css(...args)};
    }
  `;

export const portrait = (...args) => css`
  @media screen and (max-aspect-ratio: 1/1) {
    ${css(...args)};
  }
`;
