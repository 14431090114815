import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { small, portrait, medium, wide } from "../util/media";

const MAX_HEIGHT = 1650;

const HeroContainer = styled.div`
  padding: 40px var(--padding-x) 30px;
  overflow: hidden;
  display: flex;
  position: relative;
  min-height: 100%;
  max-height: ${MAX_HEIGHT}px;
  position: relative;
  flex-direction: column;

  --padding-x: 60px;

  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: ${(props) => props.theme.primary};
  }

  ${small`
    --padding-x: 20px;
    padding: 40px var(--padding-x) 10px;

    > div:first-child {
      flex: 0 0 0 !important;
    }
  `};
`;

const BgImage = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  position: fixed !important;

  img {
    object-position: right top !important;
    transform: translateX(20%) scale(1.2);
    transform-origin: right top;

    ${portrait`
      object-position: 55% top !important;
      transform: none;
      transform-origin: right top;
    `};
  }
`;

const CreditsContainer = styled.div`
  flex: 0 0 auto;
  margin-top: 20px;
`;

const Credits = styled.a.attrs({
  href: "https://twitter.com/joehudsonsmall",
  target: "_blank",
})`
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  padding: 8px 12px;
  padding-bottom: 6px;
  transition: all 0.2s ease;
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.rainbow.pink};
  }
`;

// @todo optimise image
function Hero({ image, children }) {
  return (
    <>
      <BgImage fluid={image} />
      <HeroContainer>
        <div style={{ flex: 1 }} />
        <div style={{ flex: "0 0 auto" }}>{children}</div>
        <div style={{ flex: 1 }} />
        <CreditsContainer>
          <Credits>
            Photography by <strong>Joseph Hudson-Small</strong>
          </Credits>
        </CreditsContainer>
      </HeroContainer>
    </>
  );
}

export default Hero;
