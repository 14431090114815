import React from "react";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { rgba } from "polished";

import { medium, small, portrait } from "../../util/media";
import theme from "../../util/theme";

const SocialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;

  ${portrait`
    max-width: 30%;
  `}
`;

const SocialItemGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
`;

const SocialItem = styled(({ icon, highlight: _, iconHighlight: __, ...props }) => (
  <OutboundLink target="_blank" {...props}>
    <i className={icon}></i>
  </OutboundLink>
))`
  --size: 90px;
  display: flex;
  padding: calc(var(--size) * 0.2);
  height: var(--size);
  width: var(--size);
  align-items: center;
  background: ${({ highlight }) => highlight};
  color: white;
  border-radius: var(--size);
  text-decoration: none;
  justify-content: center;
  font-size: calc(var(--size) / 1.9);

  margin-right: 30px;
  margin-bottom: 0;

  transform: scale(1);
  transform-origin: center center;
  transition: transform 0.1s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }

  ${medium`
      --size: 70px;
   `};

  ${small`
      --size: 45px;
      margin-right: 12px;
   `};
`;

function Social() {
  return (
    <SocialContainer>
      <SocialItemGroup>
        <SocialItem
          href="https://twitter.com/natehiggins"
          icon="fa-brands fa-twitter"
          highlight={theme.rainbow.blue}
        />
        <SocialItem
          href="https://www.instagram.com/nathggns/"
          icon="fa-brands fa-instagram"
          highlight={theme.rainbow.orange}
        />
        <SocialItem
          href="https://github.com/nathggns"
          icon="fa-brands fa-github"
          highlight="black"
        />
      </SocialItemGroup>
      <SocialItemGroup>
        <SocialItem
          href="mailto:media@nateh.uk"
          icon="fa-solid fa-envelope"
          target="_top"
          highlight={theme.primary}
        />
        <SocialItem
          href="https://www.linkedin.com/in/natehiggins/"
          icon="fa-brands fa-linkedin"
          highlight={theme.rainbow.blue}
        />
      </SocialItemGroup>
    </SocialContainer>
  );
}

export default Social;
