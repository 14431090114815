import React from "react";
import { Helmet } from "react-helmet-async";
import styled, { keyframes, ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { rgba } from "polished";

import "../css/style.css";

import Ticker from "../components/Ticker";
import Hero from "../components/Hero";
import Social from "../components/Social";

import theme from "../util/theme";
import { small, medium, portrait } from "../util/media";

import handUrl from "../images/hand.png";
import logo from "../images/logo.svg";

const Intro = styled.div`
  font-weight: bold;
  font-size: 4em;
  line-height: 1.5;
  max-width: 720px;
  position: relative;
  top: -0.25em;
  margin-top: 30px;
  transition: font-size;
  min-width: 200px;

  ${portrait`
    font-size: 3.5em;
    line-height: 1.6em;
  `};

  ${medium`
    font-size: 2.8em;
    line-height: 1.6em;
  `};

  ${small`
    font-size: 1.5em;
    line-height: 1.7em;
  `};
`;

const IntroText = styled.span`
  background-color: rgba(255, 255, 255, 0.8);
  box-decoration-break: clone;
  padding: 0.078em 0.31em;
  position: relative;
  top: -10px;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const Name = styled(NoWrap)`
  color: ${(props) => props.theme.primary};
`;

const WaveKeyframe = keyframes`
  to, from {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(3deg);
  }
`;

const Wave = styled.div`
  margin-left: 0.07em;
  margin-right: 0.3em;
  animation: ${WaveKeyframe} 0.4s infinite;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &:after {
    content: "H";
    font-size: 1.3em;
    line-height: 1.1em;
    color: transparent;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url(${handUrl}) no-repeat center center;
    margin-top: -5px;
    background-size: contain;
    content: "";
  }
`;

const Header = styled.div`
  margin-bottom: 50px;

  img {
    ${small`
      width: 100px;
    `}
  }
`;

const TICKER_OPTIONS = [
  { text: "Act", color: theme.rainbow.yellow },
  { text: "Campaign", color: theme.rainbow.green },
  { text: "Write", color: theme.rainbow.purple },
  { text: "Code", color: theme.rainbow.blue },
];

const Body = styled.div`
  height: 100%;
`;

function Index({ data }) {
  return (
    <>
      <Helmet>
        <title>Nate Higgins</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Body>
          <Hero image={data.me.childImageSharp.fluid}>
            <Header>
              <img src={logo} width={153} alt="" />
            </Header>
            <Intro>
              <IntroText>
                <NoWrap>
                  Hi! <Wave />
                </NoWrap>
                <NoWrap>My name is</NoWrap>
                <br />
                <Name>Nate Higgins</Name> <NoWrap>and I</NoWrap>
              </IntroText>
              <Ticker options={TICKER_OPTIONS} />
            </Intro>
            <Social />
          </Hero>
        </Body>
      </ThemeProvider>
    </>
  );
}

export const query = graphql`
  query IndexPageQuery {
    me: file(name: { eq: "me-flip" }) {
      childImageSharp {
        fluid(maxWidth: 2032) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Index;
