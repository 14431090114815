const PRIMARY = "#ff6495";

const theme = {
  primary: PRIMARY,
  foreground: "#ffffff",

  githubBlue: "#0366d6",

  rainbow: {
    pink: PRIMARY,
    purple: "#44355B",
    blue: "#257FE8",
    green: "#6AB023",
    orange: "#F06449",
    yellow: "#FFBE0B",
    red: "#CE2D4F",
    white: "#ffffff",
  },
};

module.exports = theme;
