import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useInterval from "react-useinterval";

const progressAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const TickerItem = styled.a.attrs({ href: "#" })`
  display: inline-block;
  background: ${(props) => props.color};
  padding: 0 0.31em;
  color: ${(props) => props.theme.foreground};
  white-space: nowrap;
  position: relative;
  text-decoration: none;
  text-transform: lowercase;
  &:after {
    content: ".";
  }
`;

const TickerPercentage = styled.div`
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  animation: var(--interval) ${progressAnimation} ease-in-out forwards;
`;

const TickerContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  will-change: transform;

  .slide-enter {
    transform: translateY(-100%);
  }

  .slide-enter-active,
  .slide-exit {
    transform: translateY(0%);
    top: 0;
    left: 0;
  }

  .slide-exit-active {
    position: absolute;
    // Must use the css variable because of Safari not understanding 100%
    transform: translateY(var(--height));
  }

  .slide-enter-active,
  .slide-exit-active {
    transition: transform var(--speed) cubic-bezier(0.22, 1, 0.36, 1);
  }
`;

const TickerDescription = styled.div`
  position: absolute;
  top: 100%;
  left: 100%;
`;

function Ticker({ options, interval = 3000, speed = 500 }) {
  const [index, setIndex] = useState(0);
  const [shouldResetInterval, setShouldResetInterval] = useState(false);
  const [container, setContainer] = useState(null);
  const { text, color } = options[index];
  const tick = () => {
    setIndex((index + 1) % options.length);
  };
  const onClick = (evt) => {
    evt.preventDefault();
    tick();
    setShouldResetInterval(true);
  };

  useInterval(tick, shouldResetInterval ? null : interval);

  useEffect(() => {
    if (shouldResetInterval) {
      setShouldResetInterval(false);
    }
  }, [shouldResetInterval]);

  useEffect(() => {
    if (container) {
      container.style.setProperty("--interval", `${interval}ms`);
      container.style.setProperty("--speed", `${speed}ms`);
    }
  }, [interval, container, speed]);

  useEffect(() => {
    function listener() {
      if (container) {
        container.style.setProperty("--height", `${Math.floor(container.offsetHeight)}px`);
      }
    }

    listener();

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [container]);

  return (
    <TickerContainer ref={setContainer}>
      <TransitionGroup>
        <CSSTransition key={text} timeout={speed} classNames="slide">
          <TickerItem color={color} onClick={onClick}>
            {text}
            <TickerPercentage key={text} />
          </TickerItem>
        </CSSTransition>
      </TransitionGroup>
      <TickerDescription>
        {options.map(({ text }) => (
          <span key={text}>{text},</span>
        ))}
      </TickerDescription>
    </TickerContainer>
  );
}

Ticker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  interval: PropTypes.number,
  speed: PropTypes.number,
};

export default Ticker;
